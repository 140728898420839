<template>
  <list-card
    :items="items"
    :entity-type="entityType"
    :list-actions="listActions"
    :group-actions="groupActions"
    :is-filter-active="isFilterActive"
    :sorting="sorting"
    :current-sort-by="currentSortBy"
    title="Types"
    @click:sort="handleSort"
    @click:filter-icon="handleFilterOpen"
  >
    <template #item="{select, ...props}">
      <type-list-item
        v-bind="props"
        :actions="getItemActions(props.item)"
        :to="getItemRoute(props.item)"
        @select="select"
      />
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import TypeListItem from './TypeListItem';
import { useTypeListActions } from './listActions';
import groupActions from './groupActions';

import { computed, onMounted, ref } from '@vue/composition-api';

import { useSortBy } from '@/compositions/sortBy';
import { useTypeMenu } from '@/modules/object-types/compositions/typeMenu';
import { useTypes } from '@/modules/object-types/compositions/types';
import sortingMenu from './sortingMenu';
import { useGeotagFilters } from '@/modules/geotags/compositions/geotag-filters';
import { useFilter } from '@/compositions/filter';
import { TYPE_LIST_FILTER } from '@/modules/object-types/compositions/type-filters';
import { SearchEntityEnum } from '@/compositions/globalSearch';

export default {
  name: 'Types',
  components: {
    ListCard,
    TypeListItem
  },

  setup(props, { root }) {
    const { list, load } = useTypes();

    const getItemRoute = item => ({
      name: 'type_card',
      params: {
        typeId: item.id
      },
      query: root.$route.query
    });

    const currentSortBy = ref(sortingMenu[0].id);

    const getItemActions = entity => {
      return useTypeMenu({ entity });
    };

    const keyword = computed(() => {
      return root.$route?.query?.keyword;
    });

    const searchedList = computed(() => {
      return keyword.value
        ? list.value.filter(i => i.title.includes(keyword.value))
        : list.value;
    });

    const filters = useGeotagFilters();

    const {
      filteredItems,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    } = useFilter(searchedList, filters, TYPE_LIST_FILTER);

    const { sortType, sortKey, sortedItems } = useSortBy(
      filteredItems,
      sortingMenu[0].sortKey,
      sortingMenu[0].sortType
    );

    const handleSort = e => {
      currentSortBy.value = e.id;
      sortType.value = e.sortType;
      sortKey.value = e.sortKey;
    };

    const { listActions } = useTypeListActions();

    onMounted(() => {
      load();
    });

    return {
      getItemRoute,
      items: sortedItems,
      getItemActions,
      listActions,
      groupActions,
      sorting: sortingMenu,
      currentSortBy,
      handleSort,
      list,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen,
      entityType: SearchEntityEnum.type
    };
  }
};
</script>

<style></style>
